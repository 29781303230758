import React from 'react';
import BNVIcon from '../../svg/BNVIcon';
import PNBLIcon from '../../svg/PNBLIcon';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-20 mb-8 text-gray-800">
    <div class="gap-20">
      <div class="float-left mb-20">
        <a href="https://www.bnv.ch"><BNVIcon /></a>
      </div>
      <div class="float-right mb-20">
        <a href="https://www.pronatura-bl.ch"><PNBLIcon /></a>
      </div>
    </div>
  </footer>
);

export default Footer;
