import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import { Helmet } from 'react-helmet';

const Header = () => (
  <header className="top-0 bg-white shadow">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Baselbieter Naturschutztag</title>
      <link rel="canonical" href="https://naturschutztag.ch/" />
    </Helmet>    
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <LogoIcon />
        </div>
        Baselbieter Naturschutztag
      </div>
      <div className="flex mt-4 sm:mt-0">
{/*         <AnchorLink className="px-4" href="#karte">
          Übersichtskarte
        </AnchorLink>     */}    
{/*         <AnchorLink className="px-4" href="#anlaesse">
          Anlässe
        </AnchorLink> */}
        <AnchorLink className="px-4" href="#melden">
          Anlass melden
        </AnchorLink>        
{/*         <AnchorLink className="px-4" href="#stats">
          Stats
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink> */}
      </div>
{/*       <div className="hidden md:block">
        <Button className="text-sm">Start Free Trial</Button>
      </div> */}
    </div>
  </header>
);

export default Header;
